defineDs('DanskeSpil/Domain/AvalonComponents/Scripts/Utilities/OddsetFeeder', ['Shared/Framework/Mithril/Scripts/Helpers/Utils'], function (Utils) {

  var feedServerAjax = function (requestPath) {
    var url = DS.Config.FEEDSERVER_URL + requestPath + (requestPath.indexOf('?') > -1 ? '&token=' : '?token=') + DS.Config.FEEDSERVER_TOKEN;

    var options = {
      type: 'GET',
      url: url,
      contentType: 'application/json; charset=utf-8',
      dataType: 'json'
    };

    return $DS.ajax(options);
  };

  var getExistingIntcmp = function (url) {
    if (url) {
      var i = url.indexOf('intcmp');
      if (i > -1) {
        var oldIntcmp = url.substring(i + 'intcmp='.length);
        i = oldIntcmp.indexOf('?');
        if (i > -1) {
          oldIntcmp = oldIntcmp.substring(0, i);
        }
        return oldIntcmp;
      }
    }
  };

  var showFallbackContent = function ($spot, error) {
    var $linkButton = $spot.find('.js-oddset-link-button');
    $linkButton.attr('href', $spot.data('fallback-url'));
    $spot.find('.js-oddset-image-active').hide();
    $spot.find('.js-oddset-image-fallback').show();
    $spot.find('.js-oddset-content-wrapper').hide();
    $spot.find('.js-oddset-fallback-wrapper').show();
    if (error) {
      console.debug('OddsetFeeder error:', error);
      $spot.data('outcomes', '');
      $spot.data('current-odds', '');
    }
  };

  var showOddsetContent = function ($spot) {
    var $linkButton = $spot.find('.js-oddset-link-button');
    $linkButton.attr('href', $spot.data('fallback-url'));
    $spot.find('.js-oddset-image-fallback').hide();
    $spot.find('.js-oddset-image-active').show();
    $spot.find('.js-oddset-fallback-wrapper').hide();
    $spot.find('.js-oddset-content-wrapper').hide();
    $spot.find('.js-oddset-content-wrapper').fadeIn(200);
  };

  var updateDom = function ($spot) {
    var outcomes = $spot.data('outcomes');
    var index = $spot.data('index') || 0;

    if (!outcomes || !outcomes[index]) {
      showFallbackContent($spot, 'No games or index found');
      return;
    }

    var currentOdds = '';
    var $linkButton = $spot.find('.js-oddset-link-button');
    var previousOdds = $spot.data('current-odds');
    var campaignFieldId = $linkButton.data('campaign-id');

    if (!campaignFieldId) {
      var url = $linkButton.attr('href');
      campaignFieldId = getExistingIntcmp(url);
    }

    $spot.find('.js-oddset-team-one').html(outcomes[index].home + '&nbsp;-&nbsp;');
    $spot.find('.js-oddset-team-two').html(outcomes[index].away);

    currentOdds += outcomes[index].home + outcomes[index].away;

    if (outcomes[index].odds1) {
      $spot.find('.js-oddset-odds-1').html(outcomes[index].odds1.replace('.', ','));
      $spot.find('.js-oddset-odds-1').show();
      currentOdds += outcomes[index].odds1;
    } else {
      $spot.find('.js-oddset-odds-1').hide();
    }

    if (outcomes[index].oddsX) {
      $spot.find('.js-oddset-odds-x').html(outcomes[index].oddsX.replace('.', ','));
      $spot.find('.js-oddset-odds-x').show();
      currentOdds += outcomes[index].oddsX;
    } else {
      $spot.find('.js-oddset-odds-x').hide();
    }

    if (outcomes[index].odds2) {
      $spot.find('.js-oddset-odds-2').html(outcomes[index].odds2.replace('.', ','));
      $spot.find('.js-oddset-odds-2').show();
      currentOdds += outcomes[index].odds2;
    } else {
      $spot.find('.js-oddset-odds-2').hide();
    }

    $linkButton.attr(
      'href', DS.Config.ODDSET_EVENT_URL + '/'
      + outcomes[index].id
    + (campaignFieldId ? '?intcmp=' + campaignFieldId : ''));

    currentOdds = currentOdds.trim().replace(/ /g, '');

    if (currentOdds !== previousOdds) {
      showOddsetContent($spot);
      $spot.data('current-odds', currentOdds);
    }

    index++;
    $spot.data('index', index < outcomes.length ? index : 0);
  };

  var reformatOutcomes = function (match) {
    var outcomes = match.market.outcome;

    if (!Array.isArray(outcomes)) {
      outcomes = [outcomes];
    }

    var outcomesWithValidResultTypes = outcomes.filter(function (outcome) {
      // 'H' (home team), 'A' (away team), 'D' (draw), 'L' (draw)
      return 'HADL'.indexOf(outcome.resultType) > -1;
    });

    // rely on result types, if all our outcomes have valid result types
    if (outcomes.length === outcomesWithValidResultTypes.length) {
      return outcomes.reduce(function (prev, outcome) {
        if (outcome.resultType === 'H') {
          prev.home = outcome.name;
          prev.odds1 = outcome.oddsDecimal;
        } else if (outcome.resultType === 'D' || outcome.resultType === 'L') {
          prev.oddsX = outcome.oddsDecimal;
        } else {
          prev.away = outcome.name;
          prev.odds2 = outcome.oddsDecimal;
        }
        return prev;
      }, { id: match.id });
    }

    // rely on index for our formatting
    return outcomes.reduce(function (prev, outcome, i) {
      if (i === 0) {
        prev.home = outcome.name;
        prev.odds1 = outcome.oddsDecimal;
      } else if (i === 1 && i !== outcomes.length - 1) {
        prev.oddsX = outcome.oddsDecimal;
      } else {
        prev.away = outcome.name;
        prev.odds2 = outcome.oddsDecimal;
      }
      return prev;
    }, { id: match.id });

  };

  var getOutcomes = function (data, matchId) {
    var outcomes = [];

    if (!Array.isArray(data)) {
      data = [data];
    }

    data.map(function (d) {
      var match = {};
      var market = d.market;

      if (market && Array.isArray(market)) {
        market = market[0];
      }

      if (!market || !market.outcome) {
        return;
      }

      match.id = matchId || d.id;
      match.market = market;
      var outcome = reformatOutcomes(match);
      outcomes.push(outcome);
    });

    return outcomes;
  };

  var getDataForNonSpecificMatch = function ($spot) {
    var sportsId = $spot.data('sports-id');
    var ligaId = $spot.data('liga-id');
    var number = $spot.data('number');
    var feed = feedServerAjax('sportsbook/topEventsByCriteria.json?onlymain=1&ligaid=' + ligaId + '&sportid=' + sportsId + '&antal=' + number);

    feed.success(success);
    feed.error(error);

    function success(data) {
      if (!data) {
        error(null, null, 'Has no data');
        return;
      }

      if (!Array.isArray(data)) {
        data = [data];
      }

      $spot.data('outcomes', getOutcomes(data));

      // if index is not set, it's our first run. let's update the dom instead of waiting for setInterval
      if (!$spot.data('index')) {
        updateDom($spot);
      }
    }

    function error(ignoreXhr, ignoreTextStatus, error) {
      showFallbackContent(
        $spot,
        'Oddset feed with [sportId: ' + sportsId + '], and [ligaId: ' + ligaId + ']: ' + error
      );
    }
  };

  var getDataForSpecificMatch = function ($spot) {
    var matchId = $spot.data('match-id');
    var feed = feedServerAjax('sportsbook/geteventob.json?eventid=' + matchId);

    function error(ignoreXhr, ignoreTextStatus, error) {
      showFallbackContent(
        $spot,
        'Oddset feed with [eventId: ' + matchId + ']: ' + error
      );
    }

    function success(data) {
      if (!data) {
        error(null, null, 'Has no data');
        return;
      }

      $spot.data('outcomes', getOutcomes(data, matchId));

      // if index is not set, it's our first run. let's update the dom instead of waiting for setInterval
      if (!$spot.data('index')) {
        updateDom($spot);
      }
    }

    feed.success(success);
    feed.error(error);
  };

  var updateFeed = function ($spot) {
    $spot.data('match-id') > 0 ?
      getDataForSpecificMatch($spot) :
      getDataForNonSpecificMatch($spot);
  };

  var init = function () {
    if ($DS('.mode-edit').length) return;

    $DS('.js-oddset-feed').each(function () {
      var $spot = $DS(this);

      updateFeed($spot);
      setInterval(updateFeed, 60000, $spot);
      setInterval(updateDom, 10000, $spot);
    });
  };

  init();

  if (document.querySelector('.js-oddset-feed')) {
    Utils.logDeprecated('OddsetFeeder.js - .js-oddset-feed'); // Added by MIPE 2024-05-14
  }

});



