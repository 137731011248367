defineDs('DanskeSpil/Domain/AvalonComponents/Scripts/TabsComponent', ['Shared/Framework/Mithril/Scripts/Helpers/Utils'], function (Utils) {

  var tabsNavigation = function () {
    var $tabComponent = $DS('.js-tabs-component');
    var $tabFrame = $DS('.js-tab-frame');
    var $tabNavItem = $DS('.js-tab-nav-item');
    var $tabNavLink = $DS('.js-tab-nav-link');
    var $tabLinkTitle = $DS('.js-tab-link-title');

    /// / `.each` makes it possible to have multiple tabs components on the same page  ////
    $tabComponent.each(function () {
      var $tabGroup = $DS(this);

      /// / Show the first frame ////
      $tabGroup.find($tabFrame).first().addClass('is-active');

      // Populate hrefs and id's
      $tabNavLink.each(function (index) {
        $DS(this).attr('href', '#tab' + index.toString());
      });
      $tabFrame.each(function (index) {
        $DS(this).attr('id', 'tab' + index.toString());
      });


      /// / Build the navigation ////

      // Check if there exist more than one frame in the component
      if ($tabGroup.find($tabFrame).length > 1) {

        // Populate tab names
        $tabGroup.find($tabNavItem).each(function (index) {
          if ($tabFrame[index]) {
            $DS(this).find($tabLinkTitle).text($tabFrame[index].getAttribute('data-tabname'));
          }
        });
      } else {
        var $thisFrame = $tabGroup.find($tabFrame);

        // Hide tab navigation, when just one tab
        $tabGroup.addClass('tabs-component--tabs-disabled');

        // Insert the first frame's data-name as a headline
        $DS('<h2 class="avalon-tabs-component__frame-title">'
              + $thisFrame.data('tabname') +
            '</h2>').prependTo($thisFrame);
      }
    });


    /// / Navigating between the tabs ////

    $tabNavLink.on('click', function (e) {
      e.preventDefault();

      var $this = $DS(this);
      var tabGroupId = '#' + $this.closest($tabComponent).attr('id');
      var $otherNavItems = $this.closest('li').siblings();
      var $otherFrames = $DS(tabGroupId).find($tabFrame);
      var targetFrame = $this.attr('href');

      $otherNavItems.removeClass('is-selected');
      $this.closest($tabNavItem).addClass('is-selected');

      $otherFrames.removeClass('is-active');
      $DS(targetFrame).addClass('is-active');
    });
  };

  var init = function () {
    tabsNavigation();
  };

  init();


  if (document.querySelector('.js-tabs-component')) {
    Utils.logDeprecated('TabsComponent.js - ..js-tabs-component found'); // Added by MIPE 2024-05-14
  }

});
