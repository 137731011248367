defineDs('DanskeSpil/Framework/AppUniverse/Scripts/AppUniverse/AppInstallationGuide',
  [
    'Shared/Framework/Mithril/Scripts/Core/Component',
    'Shared/Framework/Mithril/Scripts/Helpers/Utils',
    'DanskeSpil/Framework/AppUniverse/Scripts/AppUniverse/Components/VersionSelector',
    'DanskeSpil/Framework/AppUniverse/Scripts/AppUniverse/Components/InstructionsSteps',
    'DanskeSpil/Framework/AppUniverse/Scripts/AppUniverse/Helpers/AppDictionary',
    'Shared/Framework/Ensighten/Scripts/Ensighten'
  ],
  function (Component, Utils, VersionSelector, InstructionsSteps, Dictionary, Ensighten) {

    // Component:
    Component('download-app-installation-guide', [Dictionary], function (m, route, settings, property) {

      // Components:
      var root = {
        controller: function () {
          this.d = Dictionary.get;

          // Variables:
          this.property = property;
          this.property('swipperInit', false);
          this.property('toggleSelector', { name: null, state: false });
          this.baseUrl = m.prop(settings.baseUrl);
          this.deviceSelectorOptions = m.prop(settings.deviceSelectorOptions);
          this.deviceSelectorLabel = m.prop(settings.deviceSelectorLabel);
          this.installationGuideSteps = m.prop(settings.installationGuideSteps);
          this.selectLabel = m.prop(this.d('AppInstallationGuide/Choose'));
          this.optionsList = [];
          this.selectedOption = this.selectLabel();

          // Functions:

          this.deviceSelectorUpdate = function (option, populateFromUrl) {

            if (option && window.location.href.indexOf(option.name) > -1) {
              this.selectedOption = option;
              // Track the selected device
              Ensighten.pushGaEvent('installationsguide', Ensighten.getBrand(), option.name);
            }

            // redirect to correct guide
            if (typeof option === 'object' && !populateFromUrl) {
              window.location.href = window.location.origin + this.baseUrl() + '/' + option.name;
            }
          };

          // if device, version and browser are available in the url, populate selectors with these values
          var pathname = window.location.pathname.toLowerCase();
          var options = pathname.replace(this.baseUrl(), '').split('/').slice(1);

          this.deviceSelectorOptions().forEach(function (deviceObj) {
            this.optionsList.push(deviceObj);
            if (options.length === 1) {
              this.deviceSelectorUpdate(deviceObj, true);
            } else {
              this.deviceSelectorUpdate();
            }

          }.bind(this));

          Utils.logDeprecated('AppInstallationGuide.js loaded'); // Added by ANVP 2024-04-19
        },

        view: function (controller) {
          return [
            VersionSelector(controller),
            InstructionsSteps(controller),
          ];
        }

      };

      // Setup routes:
      route('/', root);

    });

  });
