defineDs('DanskeSpil/Domain/AvalonComponents/Scripts/ReadMoreSpot', ['Shared/Framework/Mithril/Scripts/Helpers/Utils'], function (Utils) {

  var readMore = function () {
    var $readMoreButton = $DS('.js-read-more-cta');
    var $readMoreText = $DS('.js-read-more-text');

    $readMoreButton.click(function () {
      // Needs to be selected with this, so it only toggles the current readmore component
      var $readMoreContent = $DS(this).parent().find('.js-read-more');

      $readMoreContent.data({ initialText: 'Læs mere', openText: 'Skjul' });

      if ($readMoreContent.is(':hidden')) {
        $readMoreText.html($readMoreContent.data('openText'));
        $readMoreButton.toggleClass('is-active');
        $readMoreContent.slideDown();
      } else {
        $readMoreText.html($readMoreContent.data('initialText'));
        $readMoreButton.toggleClass('is-active');
        $readMoreContent.slideUp();
      }
    });
  };
  $DS(function () {
    readMore();

    if (document.querySelector('.js-read-more-cta')) {
      Utils.logDeprecated('ReadMoreSpot.js - .js-read-more-cta found'); // Added by MIPE 2024-05-14
    }

  });
});











