defineDs('DanskeSpil/Domain/AvalonComponents/Scripts/Gallery', ['Shared/Framework/Mithril/Scripts/Helpers/Utils'], function (Utils) {

  // Initiate the gallery - every item inside the "gallery" div will be opened in a lightbox upon being clicked.
  var gallery = function () {
    $DS('.avalon-gallery').slickLightbox();
  };

  // Initiate the gallery
  $DS(function () {
    gallery();

    if (document.querySelector('.avalon-gallery')) {
      Utils.logDeprecated('Gallery.js - .avalon-gallery found'); // Added by MIPE 2024-05-14
    }

  });
});
