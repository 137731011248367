defineDs('DanskeSpil/Domain/AvalonComponents/Scripts/Faq', ['Shared/Framework/Mithril/Scripts/Helpers/Utils'], function (Utils) {

  var $faqItems = $DS('.js-faq__item');
  var faqItemsArray = $faqItems.toArray();
  var $faqContent = $DS('.js-faq__content');
  var $searchInput = $DS('.js-faq__search-input');

  var substringMatcher = function (obj) {
    return function findMatches(q, cb) {
      var matches;
      var substrRegex;

      // an array that will be populated with substring matches
      matches = [];

      // regex used to determine if a string contains the substring `q`
      substrRegex = new RegExp(q, 'i');

      // iterate through the pool of strings and for any string that
      // contains the substring `q`, add it to the `matches` array
      $DS.each(obj, function (ignoreI, item) {
        if (substrRegex.test(item.title) || substrRegex.test(item.abstract)) {
          matches.push(item.title.toLowerCase());
        }
      });



      if (q.length > 0 && matches.length > 0) {
        $DS.each(faqItemsArray, function (ignoreI, item) {
          $DS(item).each(function () {
            // .indexOf(matches[i].toLowerCase()) !== -1
            if ($DS.inArray($DS(this).find('.js-faq__item-title').text().toLowerCase(), matches) !== -1) {
              $faqContent.append($DS(this));
            } else {
              $DS(this).detach();
            }
          });
          cb(matches);
        });
      } else {
        $DS.each(faqItemsArray, function (ignoreI, val) {
          $faqContent.append(val);

        });
      }
    };
  };


  var init = function () {
    if (typeof window.faqDataset !== 'undefined') {
      $searchInput.typeahead({
        hint: false,
        highlight: true,
        minLength: 0
      },
      {
        name: 'titles',
        source: substringMatcher(window.faqDataset)
      });

      Utils.logDeprecated('Faq.js - window.faqDataset found'); // Added by MIPE 2024-05-14
    }
  };

  var bindEvents = function () {

    // Fire touchend event, so the input field gets blurred when clicking outside of it on IOS
    $DS('.faq').on('touchend', function (event) {
      Utils.logDeprecated('Faq.js - .faq clicked'); // Added by MIPE 2024-05-14

      if ($DS(event.target).is('.js-faq__search-input, .tt-selectable')) {
        return;
      } else {
        if ($searchInput.is(':focus')) {
          $searchInput.blur();
        }
      }
    });
  };

  $DS(function () {
    init();
    bindEvents();
  });
});
