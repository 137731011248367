defineDs('/Components/DanskeSpil/Domain/AvalonComponents/Scripts/Components/AppComplianceBarCasino', [
  'DanskeSpil/Framework/TimeUtils/Scripts/TimeUtils',
  'DanskeSpil/Domain/Authentification/Scripts/LoginCache',
], function (TimeUtils, LoginCache) {
  let pamPage;
  const startTime = new Date();
  let waitSecondsForLoginAtTime = 3;
  let loginAtTime;

  const appComplianceBar = document.querySelector('.app-compliance-bar-casino');

  if (!appComplianceBar) {
    return;
  }

  const timeStampEl = appComplianceBar.querySelector('.app-compliance-bar-casino__counter-clock');
  const hiddenBalanceEl = appComplianceBar.querySelector('.app-compliance-bar-casino__balance--hidden');
  let hasHiddenBalance = !!hiddenBalanceEl;

  function formatToDKK(number) {
    return new Intl.NumberFormat('da-DK', { style: 'currency', currency: 'DKK' }).format(number);
  }

  // Helper function to pad numbers with leading zeros
  function pad(number) {
    return number < 10 ? '0' + number : number;
  }

  // Helper function to format a Date object into "HH:MM:SS"
  function formatTime(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();
    return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
  }

  function timeSinceOrCurrentTime(dateStr) {
    const givenDate = new Date(dateStr);
    const now = new Date(TimeUtils.getCurrentDateTime());

    // If the date is in the future, return the current time in the desired format
    if (!dateStr || now < givenDate) {
      return formatTime(now);
    }

    // Calculate the difference in milliseconds
    let diff = now - givenDate;

    // Convert milliseconds to hours, minutes, and seconds
    let hours = Math.floor(diff / (1000 * 60 * 60));
    diff -= hours * (1000 * 60 * 60);
    let minutes = Math.floor(diff / (1000 * 60));
    diff -= minutes * (1000 * 60);
    let seconds = Math.floor(diff / 1000);

    // Return the time difference in "HH:MM:SS" format
    return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
  }

  function setAvailableBalance() {
    const availableBalance = LoginCache.getUserObj()?.customerInfo?.availableBalance;
    if (availableBalance) {
      const balanceEl = document.querySelector('.app-compliance-bar-casino__balance');
      if (balanceEl) {
        balanceEl.innerText = formatToDKK(availableBalance);
      }
    }
  }

  function getLoginAtTime() {
    if (loginAtTime) {
      return loginAtTime;
    }

    const userObj = LoginCache.getUserObj();
    loginAtTime = userObj?.customerInfo?.loginAt;

    if (loginAtTime) {
      return loginAtTime;
    }
  }

  const setTimeAndBalance = () => {
    setInterval(() => {
      if (!loginAtTime) {
        getLoginAtTime();
      }

      if (loginAtTime || waitSecondsForLoginAtTime < 0) {
        timeStampEl.innerText = timeSinceOrCurrentTime(loginAtTime);
        timeStampEl.classList.remove('app-compliance-bar-casino__counter-clock--hidden');

        if (hasHiddenBalance && loginAtTime) {
          setAvailableBalance();
          hiddenBalanceEl.classList.remove('app-compliance-bar-casino__balance--hidden');
          hasHiddenBalance = false;
        }

      } else {
        waitSecondsForLoginAtTime--;
      }
    }, 1000);

    setInterval(setAvailableBalance, 10000);
  };

  const interval = setInterval(() => {
    pamPage = document.querySelector('.pam-page') || document.querySelector('.payment');

    if (pamPage) {
      const styles = window.getComputedStyle(appComplianceBar);
      if (pamPage && styles) {
        pamPage.style['marginTop'] = appComplianceBar.getBoundingClientRect().height + parseFloat(styles['paddingTop']) + 'px';
      }

      setTimeAndBalance();
      clearInterval(interval);

      // Retry for no more than 20 seconds
    } else if (new Date - startTime > 20000) {
      clearInterval(interval);
    }
  }, 300);
});

