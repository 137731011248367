defineDs('DanskeSpil/Domain/ContentHub/Scripts/EloquaForm', [
  'DanskeSpil/Domain/ContentHub/Scripts/Helpers/ContentHubApi',
], function (Api) {
  const form = document.querySelector('.eloqua-form');
  const inputFields = form?.querySelectorAll('.eloqua-form__form-group input');
  const selectFields = form?.querySelectorAll('.eloqua-form__form-group select');

  const getFieldValues = () => {
    const id = form.getAttribute('data-form-id');
    const fieldValues = [...inputFields, ...selectFields]
      .filter((inputField) => inputField.id && inputField.value)
      .map((inputField) => ({
        id: inputField.id,
        value: inputField.value
      }));
    return { id, fieldValues };
  };

  const getContext = () => {
    return form.getAttribute('data-form-context') || 'dli';
  };

  const initialize = () => {
    if (!form) return;

    const cta = form.querySelector('.eloqua-form__cta');

    cta?.addEventListener('click', async (ev) => {

      ev.preventDefault();
      let canSubmit = true;

      inputFields.forEach((input) => {
        let inputIsValid = input.checkValidity();

        if (input.type === 'email' && inputIsValid) {
          // Make sure our email input field contains a domain.
          const emailHasDomain = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(input.value);
          inputIsValid = emailHasDomain;
        }

        if (!inputIsValid) {
          canSubmit = false;
        }

        input.parentElement.classList.toggle('eloqua-form__field--error', !inputIsValid);
      });

      selectFields.forEach((select) => {
        let selectIsValid = select.checkValidity();

        if (!selectIsValid) {
          canSubmit = false;
        }

        select.parentElement.classList.toggle('eloqua-form__field--error', !selectIsValid);
      });

      if (!canSubmit) return;

      await Api.sendCampaignForm(getFieldValues(), getContext());
      form.classList.add('eloqua-form--submitted');
    });

  };

  initialize();

});
