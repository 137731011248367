defineDs('DanskeSpil/Domain/AvalonComponents/Scripts/VideoGallery', ['Shared/Framework/Mithril/Scripts/Helpers/Utils'], function (Utils) {

  var videogallery = function () {

    $DS('.js-videgallery__subitem').click(function () {
      // URL:s for retreiving thumbnails
      // var thumbYouTube = 'http://img.youtube.com/vi/' + videoId + '/0.jpg';
      // var thumbVimeo = 'http://vimeo.com/api/v2/video/' + video_id + '.json?callback=showThumb';
      var $mainVideo = $DS('.js-videogallery__video-frame');
      // videoId = $DS(this).find('.hidden').data('video-id'),
      var $this = $DS(this);
      var videoUrl = $this.data('embed-url');
      var videoTitle = $this.data('embed-title');
      var videoText = $this.data('embed-text');

      $DS('.js-videogallery__subitems').find('.is-selected').removeClass('is-selected');
      $this.addClass('is-selected');

      $mainVideo.attr('src', videoUrl);
      $DS('.js-videogallery__video-title').text(videoTitle);
      $DS('.js-videogallery__video-text').text(videoText);
      $DS('html, body').animate({
        scrollTop: $this.parents('.js-videogallery').offset().top - 50
      });
    });

  };
  var init = function () {
    videogallery();
  };
  init();

  if (document.querySelector('.js-videgallery__subitem')) {
    Utils.logDeprecated('VideoGallery.js - .js-videgallery__subitem found'); // Added by MIPE 2024-05-14
  }
});
