defineDs('DanskeSpil/Domain/AvalonComponents/Scripts/VipLadder', ['Shared/Framework/Mithril/Scripts/Helpers/Utils'], function (Utils) {

  var vipLadder = function () {

    var $heading = $DS('.js-vip-ladder__item').children('.js-vip-ladder__item-title');
    var headingSizeFirst = parseInt($heading.first().css('font-size'), 10);
    var headingSizeLast = parseInt($heading.last().css('font-size'), 10);
    var fontSizeDiff = headingSizeLast - headingSizeFirst;
    var grade = fontSizeDiff / ($heading.length - 1);

    var incrementFontSize = function () {
      if ($heading.length > 2) {
        var i = 0;
        $heading.each(function () {
          var fontSize = headingSizeFirst + (grade * i);
          $DS(this).css('font-size', fontSize + 'px');
          i++;
        });
      }
    };
    incrementFontSize();
  };

  var init = function () {
    vipLadder();
  };
  init();

  if (document.querySelector('.js-vip-ladder')) {
    Utils.logDeprecated('VipLadder.js - .js-vip-ladder found'); // Added by MIPE 2024-05-14
  }
});
