defineDs('DanskeSpil/Framework/AppUniverse/Scripts/AppUniverse/AppDetails',
  [
    'Shared/Framework/Mithril/Scripts/Core/Component',
    'Shared/Framework/Mithril/Scripts/Helpers/Utils',
    'Shared/Framework/Mithril/Scripts/Helpers/Render',
    'Shared/Framework/Mithril/Scripts/Helpers/ApiRequest',
    'DanskeSpil/Framework/AppUniverse/Scripts/AppUniverse/Settings/SliderSettings',
    'Common/Framework/EventHandling/Scripts/OutsideClick',
    'DanskeSpil/Framework/AppUniverse/Scripts/AppUniverse/Helpers/AppDictionary',
    'Shared/Framework/Ensighten/Scripts/Ensighten',
    'Shared/Framework/Mithril/Scripts/Helpers/Picture'
  ],
  function (Component, Utils, Render, ApiRequest, SliderSettings, OutsideClick, Dictionary, Ensighten, Picture) {

    // Component:
    Component('app-details', [Dictionary], function (m, route, settings, property) {

      // Components:
      var root = {
        controller: function () {
          this.d = Dictionary.get;
          var device = 'phone';

          // Variables:
          var baseURL = DS.Config.CONTEXTPREFIX + '/scapi/danskespil/appuniverse/sendsms';
          this.render = m.prop(Render());
          this.property = property;

          this.toggleOption = m.prop(device);
          this.selectedAreaCode = m.prop(45);
          this.openCountryCodeDropdown = m.prop(false);
          this.errorMessage = m.prop(null);
          this.successMessage = m.prop(null);
          this.appIconSrc = m.prop(settings.appIconSrc);
          this.appHeaderText = m.prop(settings.appHeaderText);
          this.appDescriptionText = m.prop(settings.appDescriptionText);
          this.appDownloadLinkText = m.prop(settings.appDownloadLinkText);
          this.appDownloadLinkUrl = m.prop(settings.appDownloadLinkUrl);
          this.appInstallationSteps = m.prop(settings.appInstallationSteps[this.toggleOption()]);
          this.installationsGuideLinkText = m.prop(this.d('InstallationsGuideLinkText'));
          this.installationsGuideLinkURL = m.prop(settings.installationsGuideLinkURL);
          this.installationsGuideLinkEnable = m.prop(!settings.appPanelInstallationGuideLinkDisable);
          this.sendSmsLoading = m.prop(false);
          this.sendSmsSendingText = m.prop(this.d('AppDetails/SmsSendingText'));
          this.sendSmsHeaderText = m.prop(settings.sendSmsHeaderText);
          this.sendSmsDescriptionText = m.prop(settings.sendSmsDescriptionText);
          this.sendSmsPhoneNumber = m.prop('');
          this.sendSmsPhoneNumberPlaceHolder = m.prop(settings.sendSmsPhoneNumberPlaceHolder);
          this.sendSmsCtaText = m.prop(settings.sendSmsCtaText);
          this.sendSmsMessages = m.prop({
            success: this.d('AppDetails/SmsMessages_Success'),
            defaultError: this.d('AppDetails/SmsMessages_DefaultError'),
            notEightDigits: this.d('AppDetails/SmsMessages_NotEightDigits'),
            notSixDigits: this.d('AppDetails/SmsMessages_NotSixDigits')
          });

          this.yourLuck = {
            images: settings.yourLuckBackgroundImages,
            show: settings.showYourLuck && settings.yourLuckBackgroundImages.length !== 0,
            backgroundIndex: 0,
            heading: settings.yourLuckHeading,
            description: settings.yourLuckDescription,
            inspirationCtaText: settings.yourLuckInspirationCtaText,
            ctaUrl: settings.yourLuckCtaUrl,
            ctaTitle: settings.yourLuckCtaTitle,
            showCta: settings.yourLuckShowCta
          };

          this.countries = m.prop([
            // Denmark
            { countryCode: 'dk', areaCode: 45, name: this.d('AppDetails/CountryName_DK') },
            // Faroe Islands
            { countryCode: 'fo', areaCode: 298, name: this.d('AppDetails/CountryName_FO') },
            // Greenland
            { countryCode: 'gl', areaCode: 299, name: this.d('AppDetails/CountryName_GL') }
          ]);

          // Functions:
          this.sendLink = function () {
            if (this.sendSmsLoading()) {
              // already sending sms
              return;
            }

            var areaCode = this.selectedAreaCode();
            var phoneNumber = this.sendSmsPhoneNumber().trim();

            if (areaCode === 45 && phoneNumber.length !== 8) {
              this.showError(this.sendSmsMessages().notEightDigits);
              return;
            }

            if (areaCode !== 45 && phoneNumber.length !== 6) {
              this.showError(this.sendSmsMessages().notSixDigits);
              return;
            }

            this.sendSmsLoading(true);

            ApiRequest({
              method: 'POST',
              data: {
                phonenumber: areaCode + phoneNumber,
                textguid: settings.sendSmsID
              },
              url: baseURL
            }).then(function () {
              this.showSuccess(this.sendSmsMessages().success);
              this.sendSmsLoading(false);

              // Track send sms event
              Ensighten.pushGaEvent('app_download', Ensighten.getBrand(), 'sms');

              m.redraw();
            }.bind(this), function (error) {
              if (error.errors && error.errors[0] && error.errors[0].Message &&
                this.sendSmsMessages()[error.errors[0].Message]
              ) {
                this.showError(this.sendSmsMessages()[error.errors[0].Message]);
              } else {
                var errorMessage = this.sendSmsMessages().defaultError +
                  '<br>Fejlkode: ' + error.errors[0].Message;
                this.showError(errorMessage);
              }
              this.sendSmsLoading(false);
              m.redraw();
            }.bind(this));
          }.bind(this);

          this.showSuccess = function (message) {
            var messageWithNumber = message.replace('[number]',
              '+' + this.selectedAreaCode() + ' ' + this.sendSmsPhoneNumber().trim());
            this.successMessage(messageWithNumber);
          }.bind(this);

          this.showError = function (message) {
            this.errorMessage(message);
          }.bind(this);

          this.keyup = function (e) {
            if (this.sendSmsLoading()) {
              // already sending sms
              return;
            }

            // remove any error message displayed
            this.errorMessage(null);
            this.successMessage(null);

            // key is 'enter', so submit the form
            if (e.keyCode === 13) {
              this.sendLink();
            }

            // update number if key is a digit, delete or backspace key
            if (e.key.match(/\d/) || e.keyCode === 8 || e.keyCode === 46) {
              this.sendSmsPhoneNumber(e.target.value);
            }
          }.bind(this);

          this.changeCountryCode = function (code) {
            return function () {
              this.selectedAreaCode(code);
              this.openCountryCodeDropdown(false);
              m.redraw();
            }.bind(this);
          }.bind(this);

          this.toggleCountryCodeDropdown = function () {
            if (this.openCountryCodeDropdown()) {
              this.openCountryCodeDropdown(false);
            } else {
              this.openCountryCodeDropdown(true);
            }
            m.redraw();
          }.bind(this);

          // Render:
          this.render().ready.then(function (nodes) {
            var sendSmsButton = nodes['js-send-sms-button'];
            OutsideClick(sendSmsButton.$element, sendSmsButton.context, function () {
              if (sendSmsButton.$element.classList.contains('open')) {
                this.toggleCountryCodeDropdown();
              }
            }.bind(this));
          }.bind(this));

          /**
           * Lookup the countryCode of the currently selected areaCode
           */
          this.getCurrentCountryCode = function () {
            var code = this.selectedAreaCode();
            var countries = this.countries();

            var rtn = null;

            for (var i = 0; i < countries.length; i++) {

              if (countries[i].areaCode === code) {
                rtn = countries[i].countryCode;
                break;
              }

            }
            return rtn;

          }.bind(this);

          this.downloadCta = function (modifier) {
            return m('.app-details__download' + (modifier ? '.app-details__download' + modifier : ''), [
              m('.app-details__download-cta',
                m('a.app-universe__button--cta', {
                  href: this.appDownloadLinkUrl()
                }, this.appDownloadLinkText())
              ),
              this.installationsGuideLinkEnable() && this.installationsGuideLinkText() ?
                m('.app-details__download-guide',
                  m('a.app-universe__button--installation-guide' + (modifier ? '.app-universe__button--installation-guide' + modifier : ''), {
                    href: this.installationsGuideLinkURL()
                  }, this.installationsGuideLinkText())
                ) : ''
            ]);
          }.bind(this);

          this.sendSms = function (render, modifier) {
            return m('.app-details__send-sms' + (modifier ? '.app-details__send-sms' + modifier : ''),
              {
                class: this.errorMessage() ? 'app-details__send-sms--error' : ''
              }, [
                m('.app-description', [
                  m('h2', this.sendSmsHeaderText()),
                  m('p', this.sendSmsDescriptionText())
                ]),
                m('.app-details__send-sms-content', [
                  this.successMessage() ? '' :
                    m('.app-details__send-sms-input', [
                      m('button[type=button].country-dropdown-trigger',
                        {
                          onclick: this.toggleCountryCodeDropdown,
                          class: this.openCountryCodeDropdown() ? 'open' : '',
                          config: render.depend('js-send-sms-button')
                        }, [
                          m('span',
                            {
                              class: 'country-dropdown-trigger__flag'
                            },
                            m('img', {
                              class: 'country-dropdown-trigger__svg',
                              src: '/Components/DanskeSpil/Framework/AppUniverse/Graphics/Flags/' + this.getCurrentCountryCode() + '.svg'
                            })),
                          m('span', '+' + this.selectedAreaCode())
                        ]),

                      m('.country-dropdown',
                        {
                          class: this.openCountryCodeDropdown() ? '' : 'hide'
                        },
                        this.countries().map(function (item) {
                          return m('.country-dropdown__item',
                            {
                              onclick: this.changeCountryCode(item.areaCode),
                              class: this.selectedAreaCode() === item.areaCode ? 'active' : ''
                            },
                            item.name
                          );
                        }.bind(this))
                      ),
                      m('input[type=tel]', {
                        placeHolder: this.sendSmsPhoneNumberPlaceHolder(),
                        onkeyup: this.keyup
                      })
                    ]),
                  this.successMessage() ? '' :
                    m('.app-details__send-sms-cta', [
                      m('.app-universe__button--cta', {
                        onclick: this.sendLink
                      }, this.sendSmsLoading() ?
                        [
                          this.sendSmsSendingText(),
                          m('.app-universe__button-flash')
                        ] :
                        this.sendSmsCtaText())
                    ]),
                  this.errorMessage() ?
                    m('.app-details__message',
                      m('.app-description',
                        m('p', m.trust(this.errorMessage()))
                      )
                    )
                    : '',
                  this.successMessage() ?
                    m('.app-details__message',
                      m('.app-description',
                        m('p', m.trust(this.successMessage()))
                      )
                    )
                    : '',
                  this.installationsGuideLinkEnable() && this.installationsGuideLinkText() ?
                    m('a.app-universe__button--installation-guide',
                      { href: this.installationsGuideLinkURL() },
                      this.installationsGuideLinkText()) : '',
                ])
              ]);
          }.bind(this);

          this.changeBackground = function () {
            var isShowingLastImage = this.yourLuck.backgroundIndex === this.yourLuck.images.length - 1;
            this.yourLuck.backgroundIndex = isShowingLastImage ? 0 : this.yourLuck.backgroundIndex + 1;
          }.bind(this);

          Utils.logDeprecated('AppDetails.js loaded'); // Added by ANVP 2024-04-19
        },

        view: function (controller) {
          var render = controller.render();
          this.applySlider = function (element, init, ignoreContext) {
            if (!init) {
              m.startComputation();

              var $phoneStepsSlider = $DS(element);

              $phoneStepsSlider.on('init', function () {
                m.endComputation();
              });

              $phoneStepsSlider.on('destroy', function () {
                m.endComputation();
              });

              if ($phoneStepsSlider.hasClass('slick-initialized')) {
                $phoneStepsSlider.slick('unslick');
              }

              $phoneStepsSlider.not('.slick-initialized').slick(SliderSettings.instructionSteps());
            }
          }.bind(this);

          return m('.app-details', [
            m('.app-details__header' + (controller.yourLuck.show ? '.app-details__header--your-luck' : ''), [
              !controller.yourLuck.show ?
                m('.container', [
                  m('.app-details__logo',
                    m('img', { src: controller.appIconSrc() })
                  ),
                  m('.app-details__description.app-description', [
                    m('h1', controller.appHeaderText()),
                    m('p', controller.appDescriptionText())
                  ]),
                  m('.app-details__content', [
                    controller.downloadCta(),
                    controller.sendSms(render)
                  ])
                ]) : m('div', [
                  Picture
                    .mobile(controller.yourLuck.images[controller.yourLuck.backgroundIndex][0])
                    .sm(controller.yourLuck.images[controller.yourLuck.backgroundIndex][1])
                    .lg(controller.yourLuck.images[controller.yourLuck.backgroundIndex][2])
                    .output(),
                  m('.your-luck__container', [
                    m('.your-luck__description', [
                      m('h1.heading', controller.yourLuck.heading),
                      m('p.description', controller.yourLuck.description)
                    ]),
                    controller.yourLuck.showCta ?
                      controller.yourLuck.ctaUrl && controller.yourLuck.ctaTitle ?
                        m('a.your-luck__find-inspiration', {
                          href: controller.yourLuck.ctaUrl
                        }, [
                          m('img.your-luck__logo', { src: controller.appIconSrc() }),
                          m('.your-luck__cta-text-wrapper',
                            m('.your-luck__text', controller.yourLuck.ctaTitle)
                          )
                        ]) :
                        m('.your-luck__find-inspiration', {
                          onclick: function (event) {
                            controller.changeBackground();
                            var domElement = event.currentTarget;
                            // If already animating -> return early
                            if (domElement.classList.contains('your-luck__find-inspiration--animate')) return;

                            domElement.classList.add('your-luck__find-inspiration--animate');
                            // Wait for ::before animation to end
                            setTimeout(function () {
                              domElement.classList.remove('your-luck__find-inspiration--animate');
                            }, 400);
                          }
                        }, [
                          m('img.your-luck__logo', { src: controller.appIconSrc() }),
                          m('.your-luck__text', controller.yourLuck.inspirationCtaText)
                        ]) : null,
                    controller.downloadCta(controller.yourLuck.show ? '--your-luck' : null)
                  ])
                ])
            ]),
            controller.yourLuck.show ? controller.sendSms(render, '--your-luck') : null,
            m('.app-details__slider', [
              m('.phone-tablet-steps', [
                m('.phone-tablet-steps__container.js-slider-phone-tablet-steps', {
                  config: this.applySlider
                }, [
                  controller.appInstallationSteps().map(function (installationStep) {
                    return m('img.phone-tablet-steps__step.slick-slide', {
                      src: installationStep, alt: ''
                    });
                  })
                ])
              ])
            ])
          ]);
        }
      };

      // Setup routes:
      route('/', root);

    });

  });
