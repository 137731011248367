defineDs('DanskeSpil/Framework/AppUniverse/Scripts/AppUniverse/Components/InstructionsSteps',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Framework/AppUniverse/Scripts/AppUniverse/Settings/SliderSettings'
  ],
  function (m, SliderSettings) {

    // Template:
    var InstructionsSteps = function (controller) {

      this.applySlider = function (element, init, ignoreContext) {
        if (!init) {
          m.startComputation();

          var $phoneStepsSlider = $DS(element);

          $phoneStepsSlider.on('init', function () {
            m.endComputation();
            var time = 1000;

            $phoneStepsSlider.find('.instructions-steps__step').each(function () {
              setTimeout(function () {
                $DS(this).removeClass('reveal');
              }.bind(this), time);
              time += 50;
            });
          });

          $phoneStepsSlider.on('destroy', function () {
            m.endComputation();
          });

          $phoneStepsSlider.on('click', function (e) {
            var index = $DS(e.target).parents('.instructions-steps__step').attr('data-slick-index');

            var inststeps = controller.installationGuideSteps();
            inststeps[index]['reveal'] = !inststeps[index]['reveal'];
            controller.installationGuideSteps(inststeps);
            m.redraw();
          });

          if ($phoneStepsSlider.hasClass('slick-initialized')) {
            $phoneStepsSlider.slick('unslick');
          }

          $phoneStepsSlider.not('.slick-initialized').slick(SliderSettings.instructionSteps());
        }
      }.bind(this);

      // Return:
      return m('.instructions-steps', [
        m('.instructions-steps__container.js-instruction-steps',
          {
            config: this.applySlider
          },
          [
            controller.installationGuideSteps().map(function (step, index) {

              return m('.instructions-steps__step.slick-slide' + (step['reveal'] ? ' reveal' : ''), [
                m('.instructions-steps__step-img', {
                  style: 'background-image: url(' + step.stepImageURL + ')'
                }, [m('img.instructions-steps__step-sizerimg', { src: step.stepImageURL, style: 'visibility: hidden;' }, '')]),
                m('.instructions-steps__step-body', [
                  m('.instructions-steps__arrow'),
                  m('.instructions-steps__step-number', index + 1),
                  m('.instructions-steps__step-text', m.trust(step.stepText))
                ])
              ]);
            })
          ]),
      ]);
    };

    // Public functions:
    return InstructionsSteps;

  });
