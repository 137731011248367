defineDs('DanskeSpil/Domain/AvalonComponents/Scripts/Carousel', function () {

  var carousel = function () {

    // On init run this
    $DS('.avalon-carousel__slider').on('init', function () {
      var $this = $DS(this);
      // Add the class reveal to current active slide to highlight it
      $this.find('div.slick-current div.caption').addClass('reveal');
      // Add an event - when a slide is clicked but not active, it will slide to the center and become active
      $this.find('div.slide img').on('browse', function () {
        $DS('.avalon-carousel__slider').slick('slickGoTo', $DS(this).parent().attr('data-slick-index'));
      });
    });

    // Init the carousel
    var nDots = $DS('.avalon-carousel__slider').attr('data-dots');
    var nArrows = $DS('.avalon-carousel__slider').attr('data-arrows');

    if (!nArrows) {
      nArrows = '0';
    }

    var carousel = $DS('.avalon-carousel__slider').slick({
      centerMode: true,
      slidesToShow: 1,
      centerPadding: '30%',
      dots: nDots === 'True',
      infinite: true,
      arrows: nArrows !== '0',
      edgeFriction: .5,
      cssEase: 'ease-in-out',
      focusOnSelect: false,
      lazyLoad: 'progressive',
      responsive: [
        {
          breakpoint: 767,
          settings: {
            centerPadding: '10%',
          }
        },
        {
          breakpoint: 1024,
          settings: {
            centerPadding: '15%',
          }
        }
      ]
    });

    // Set colors
    var color = $DS('.avalon-carousel__slider').attr('data-color');
    $DS('.slick-arrow').css('border-color', color);
    $DS('.avalon-carousel .slick-dots li').css('background-color', color);
    $DS('.avalon-carousel .slick-dots li.slick-active').css('background-color', color);

    // Arrows control
    if (nArrows === '1') {
      $DS('.slick-next').hide();
    }

    if (nArrows === '2') {
      $DS('.slick-prev').hide();
    }

    // Before changing slide, remove the reveal class to not highlight it anymore
    carousel.on('beforeChange', function () {
      $DS(this).find('div.slick-current div.caption').removeClass('reveal');
    });

    // After changing slide, add the reveal class and update the navigation dots to the current slide
    carousel.on('afterChange', function () {
      $DS(this).find('div.slick-current div.caption').addClass('reveal').find('li').eq($DS(this).slick('slickCurrentSlide')).addClass('slick-active');
    });

    // Init the lightbox for the carousel
    $DS('.avalon-carousel__slider').slickLightbox({
      src: 'src',
      itemSelector: '.slide img',
      slick: {
        infinite: false,
        cssEase: 'ease-in-out'
      },
      // The lightbox should only open if the slide clicked is the active/highlighted one, else browse to the one clicked on.
      shouldOpen: function (ignoreSlickLightbox, $clickedElement) {
        if ($clickedElement.parent().attr('data-slick-index') == carousel.slick('slickCurrentSlide')) {
          return true;
        } else {
          $clickedElement.trigger('browse');
          return false;
        }
      }
    }).on({
      // When the lightbox opens, disable navigation of the carousel
      'show.slickLightbox': function () {
        carousel.slick('slickSetOption', 'accessibility', false, false);
      },
      // When the lightbox is hiding, browse to the slide in the carousel that was exited upon in the lightbox
      'hide.slickLightbox': function () {
        carousel.slick('slickGoTo', $DS('.slick-active .slick-lightbox-slick-item-inner').parent().attr('data-slick-index'), true);
      },
      // After the lightbox has been hidden, re-enable keyboard navigation on the carousel and update the navigation list to current slide
      'hidden.slickLightbox': function () {
        carousel.slick('slickSetOption', 'accessibility', true, false);
        carousel.find('.slick-slide.slick-active.slick-current li').eq(carousel.slick('slickCurrentSlide')).addClass('slick-active');
      }
    });
  };
  $DS(function () {
    !$DS('body').hasClass('mode-edit') ? carousel() : '';
  });
});







