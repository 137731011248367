defineDs('/Components/DanskeSpil/Domain/AvalonComponents/Scripts/Components/Glossary', function () {
  const glossary = document.querySelector('.glossary');

  if (!glossary) return;

  const searchElement = glossary.querySelector('input.glossary__input');
  const searchIcon = glossary.querySelector('.glossary__search-icon');
  const indexElement = glossary.querySelector('.glossary__index');
  const IndexLetterElements = glossary.querySelectorAll('.glossary__index-letter');
  const entryElements = glossary.querySelectorAll('.glossary__entry');
  const entryLetterElements = glossary.querySelectorAll('.glossary__entry-letter');
  const toTopElements = glossary.querySelectorAll('.glossary__entry-to-top');
  let currentRelativePathQuery = '';

  const toggleShowNoEntriesTextIfNoEntriesFound = () => {
    const visibleEntries = document.querySelectorAll(
      '.glossary__entry:not(.glossary__entry--hide)'
    );

    glossary.classList.toggle('glossary--no-entries', visibleEntries.length === 0);
  };

  const updateSearchQueryParam = (value) => {
    if ('URLSearchParams' in window) {
      const searchParams = new URLSearchParams(window.location.search);
      let newRelativePathQuery;

      if (value) {
        searchParams.set('ord', value);
        newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
      } else {
        searchParams.delete('ord');
        newRelativePathQuery = window.location.pathname;
      }

      if (currentRelativePathQuery !== newRelativePathQuery) {
        currentRelativePathQuery = newRelativePathQuery;
        history.pushState(null, '', newRelativePathQuery);
      }
    }
  };

  const handleSearch = (ev) => {
    const value = ev?.target.value.toLowerCase() || '';

    entryLetterElements.forEach(() => {
      glossary.classList.toggle('glossary--searching', !!value);
    });

    updateSearchQueryParam(value);
    clearSelectedIndex();

    entryElements.forEach((entry) => {
      if (value) {
        const doesContainWord = entry.querySelector('.glossary__entry-word').innerText.toLowerCase().includes(value);
        entry.classList.toggle('glossary__entry--hide', !doesContainWord);
      } else {
        entry.classList.remove('glossary__entry--hide');
      }
    });

    toggleShowNoEntriesTextIfNoEntriesFound();
  };

  const handleClearInput = () => {
    searchElement.value = '';
    handleSearch();
  };

  const clearSelectedIndex = () => {
    IndexLetterElements.forEach((letter) =>
      letter.classList.remove('glossary__index-letter--selected')
    );
  };

  const handleIndexLetter = (ev) => {
    handleClearInput();
    IndexLetterElements.forEach((letter) =>
      letter.classList.remove('glossary__index-letter--selected')
    );
    ev.target.classList.add('glossary__index-letter--selected');
  };

  const selectCurrentIndexLetter = () => {
    if (window.innerWidth >= 1216) return;

    const glossTop = indexElement.offsetTop - 70;

    [...entryLetterElements].every((letter, i) => {
      if ((letter.offsetTop) > glossTop) {
        document.querySelectorAll('.glossary__index-letter').forEach((letter) => letter.classList.remove('glossary__index-letter--selected'));

        if (i === 0) i++;
        IndexLetterElements[i - 1].classList.add('glossary__index-letter--selected');

        return false;
      }
      return true;
    });
  };

  const searchForWord = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const search = urlParams.get('ord');

    if (search) {
      // Setting the value
      searchElement.value = search;

      // Creating a new 'input' event
      // eslint-disable-next-line no-restricted-globals
      let event = new Event('input', {
        bubbles: true,
        cancelable: true
      });

      // Dispatching the event
      searchElement.dispatchEvent(event);
    }
  };

  const initialize = () => {
    if (!glossary) return;

    searchElement.addEventListener('input', handleSearch);
    searchIcon.addEventListener('click', handleClearInput);

    toTopElements.forEach((toTop) => toTop.addEventListener('click', () => {
      clearSelectedIndex();
    }));

    IndexLetterElements.forEach((letter) =>
      letter.addEventListener('click', handleIndexLetter)
    );

    selectCurrentIndexLetter();

    window.addEventListener('scroll', selectCurrentIndexLetter, {
      passive: true
    });

    searchForWord();
  };

  initialize();

});

