defineDs('DanskeSpil/Domain/AvalonComponents/Scripts/Components/LeaderBoard',
  [
    'Shared/Framework/Mithril/Scripts/Core/Component',
    'DanskeSpil/Domain/Authentification/Scripts/LoginCache',
    'Common/Framework/EventHandling/Scripts/CrossWindowEvents',
    'DanskeSpil/Domain/AvalonComponents/Scripts/Components/Pagination',
    'Shared/Framework/Mithril/Scripts/Helpers/Utils'
  ],
  function (Component, LoginCache, CrossWindowEvents, Pagination, Utils) {

    Component('leaderboard', function (m, route, settings) {
      var root = {

        // controller
        controller: function () {

          // variables
          var participantsArr = [];
          this.loadingFeed = m.prop(true);
          this.currentPage = m.prop(0);
          this.settings = m.prop(settings);
          this.paginatedParticipants = m.prop();
          this.paginationSize = m.prop(settings.paginationSize || 50);
          this.lastFeedUpdate = m.prop();
          this.error = m.prop();
          this.isLoggedIn = m.prop(false);
          this.userDetails = m.prop({});
          this.searchingForParticipant = m.prop();

          if (!this.settings().tournamentId) {
            this.error('NO_TOURNAMENT_ID');
            m.redraw();
          }

          // functions
          var getUserDetails = function (users) {
            var alias;

            if ($DS('body.is-logged-in').length && LoginCache.getUserObj() && LoginCache.getUserObj().customerInfo) {
              alias = LoginCache.getUserObj().customerInfo.alias;
            }

            if (!this.isLoggedIn() || !alias) {
              return {};
            }

            var userDetails = {
              screenName: alias
            };

            // add any additional information on user
            users.forEach(function (user) {
              if (alias === user.screenName) {
                userDetails.rank = user.rank;
                userDetails.points = user.points;
                userDetails.prize = user.prize;
              }
            });

            return userDetails;
          }.bind(this);

          this.dateFormatter = function (d) {
            return new Date(d).toLocaleString('da-DK', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit'
            }).replace(' ', ' kl. ');
          };

          this.participants = function () {
            if (!this.searchingForParticipant()) {
              return participantsArr;
            }
            return participantsArr.filter(function (participant) {
              return (participant.screenName).trim().toLowerCase().indexOf(this.searchingForParticipant()) > -1;
            }.bind(this));
          }.bind(this);

          this.searchForParticipant = function (ev) {
            ev.preventDefault();
            this.currentPage(0);
            this.searchingForParticipant(ev.target.value.trim().toLowerCase() || null);
            m.redraw();
          }.bind(this);

          var loadParticipants = function () {
            var req = $DS.get(DS.Config.TOURNAMENT_LEADERBOARD + '/' + this.settings().tournamentId);

            req.done(function (response) {
              this.lastFeedUpdate(this.dateFormatter(new Date()));
              if (response && response.length > 0) {
                this.userDetails(getUserDetails(response));
                participantsArr = response;
              }
              this.loadingFeed(false);
              m.redraw();
            }.bind(this));

            req.fail(function (err) {
              this.error('ERROR_GETTING_FEED');
              console.error('error', err.responseJSON);
              this.loadingFeed(false);
              m.redraw();
            }.bind(this));
          }.bind(this);

          this.paginateParticipants = function (participants) {
            // A counter keeping track of the index in the block array.
            // Should be -1 as it will always increment on first run.
            var blockCounter = -1;

            var paginatedParticipants = participants.reduce(function (prev, curr, index) {
              if (index % this.paginationSize() === 0) {
                // This is always true on first run and on every blockSize run.
                // We use it to generate a new array in prev to push our curr into.
                blockCounter++;
                prev[blockCounter] = [];
              }

              // Add current winner to array
              prev[blockCounter].push(curr);
              return prev;
            }.bind(this), []);

            this.paginatedParticipants(paginatedParticipants);
          }.bind(this);

          var init = function () {
            this.isLoggedIn(LoginCache.isLoggedIn());
            loadParticipants();
          }.bind(this);

          CrossWindowEvents.subscribe('ds.event.userLoggedIn', function () {
            this.isLoggedIn(true);
            loadParticipants();
          }.bind(this));

          CrossWindowEvents.subscribe('ds.event.userLoggedOut', function () {
            this.isLoggedIn(false);
            loadParticipants();
          }.bind(this));

          init();
        },

        // view
        view: function (ctrl) {
          var userDetails = ctrl.userDetails();
          var participants = ctrl.participants();
          var shouldDisplayInformationBox = (ctrl.settings().dateForPrizePayment || ctrl.settings().rateRequiredToWinPrize || ctrl.settings().readMoreLinkUrl);

          ctrl.paginateParticipants(participants);

          var infoBlock = function () {
            if (shouldDisplayInformationBox) {
              return m('.leaderboard__information', [

                // date for prize payout
                ctrl.settings().dateForPrizePayment ? m('.leaderboard__information-block', [
                  m('.leaderboard__infotext-label', 'Dato for præmieudbetaling'),
                  m('.leaderboard__infotext-text', ctrl.settings().dateForPrizePayment),
                ]) : '',

                // points required to win prize
                ctrl.settings().rateRequiredToWinPrize ? m('.leaderboard__information-block', [
                  m('.leaderboard__infotext-label', 'Placering krævet for at vinde præmie'),
                  m('.leaderboard__infotext-text', ctrl.settings().rateRequiredToWinPrize),
                ]) : '',

                // read more
                ctrl.settings().readMoreLinkUrl ? m('.leaderboard__information-block', [
                  m('.leaderboard__infotext-label',
                    m('a.leaderboard__infotext-label--link', {
                      href: ctrl.settings().readMoreLinkUrl,
                      title: ctrl.settings().readMoreLinkAltText,
                      target: ctrl.settings().readMoreLinkTarget,
                    }, ctrl.settings().readMoreLinkText)
                  ),
                ]) : '',
              ]);
            }
          };

          return [

            m('.u-grid.u-grid--medium', {
              class:
                (ctrl.isLoggedIn() ? 'leaderboard__user-is-logged-in' : '') +
                (ctrl.userDetails().rank ? ' leaderboard__user-has-rank' : '')
            }, [

              ctrl.error() === 'NO_TOURNAMENT_ID' ?
                m('.leaderboard__error',
                  'Vi kan ikke finde den indtastede turnering. Prøv venligst igen senere eller tag fat i kundecenteret på 36728080'
                ) :
                ctrl.error() === 'ERROR_GETTING_FEED' ?
                  m('.leaderboard__error',
                    'Der skete en fejl da vi prøvede at hente vindere. Prøv venligst igen senere eller tag fat i kundecenteret på 36728080'
                  ) :

                  [
                    m('.leaderboard__title', {
                      class: (!shouldDisplayInformationBox ? 'leaderboard__info-not-present' : '')
                    }, ctrl.settings().title),

                    // content for when user is in the tournament
                    ctrl.isLoggedIn() ?

                      m('.leaderboard__user-block', {
                        class: (!shouldDisplayInformationBox ? 'leaderboard__info-not-present' : '')
                      }, [
                        m('.leaderboard__user-details', [

                          // placement
                          ctrl.userDetails().rank ? m('.leaderboard__placement', [
                            m('.leaderboard__placement--label', 'Aktuel placering'),
                            m('.leaderboard__placement--rank', ctrl.userDetails().rank),
                          ]) : '',

                          // alias
                          m('.leaderboard__useralias', userDetails.screenName),

                          // user points
                          m('.leaderboard__user-points', [

                            // points
                            m('.leaderboard__user-points-block', [
                              m('.leaderboard__infotext-label', 'Dine optjente point'),
                              m('.leaderboard__infotext-text', Utils.numberFormat(userDetails.points)),
                            ]),

                            // prize
                            m('.leaderboard__user-points-block', [
                              m('.leaderboard__infotext-label', 'Din præmie lige nu'),
                              m('.leaderboard__infotext-text',
                                userDetails &&
                                userDetails.prize &&
                                userDetails.prize[0] &&
                                userDetails.prize[0].prize ?
                                  userDetails.prize[0].prize : 'Ingen præmie'
                              ),
                            ]),
                          ]),

                        ]),

                        // place information next to user-block
                        ctrl.isLoggedIn() ? infoBlock() : '',

                      ]) : '',

                    // leaderboard
                    m('.leaderboard__board', [
                      m('.leaderboard__board-top', [

                        // board info
                        m('.leaderboard__board-info', [
                          m('.leaderboard__board-header', 'Samlet leaderboard'),
                          m('.leaderboard__board-subheader', ctrl.lastFeedUpdate() ? 'Sidst opdateret ' + ctrl.lastFeedUpdate() : ''),
                        ]),

                        // board search
                        m('.leaderboard__board-search', [
                          m('span.leaderboard__input',
                            m('input', {
                              placeholder: 'Søg efter spillernavn',
                              onkeyup: ctrl.searchForParticipant
                            })
                          )
                        ]),
                      ]),

                      // table
                      m('table.leaderboard__table', [
                        m('tr', [
                          m('th.only-small', '#'),
                          m('th.not-small', 'Placering'),
                          m('th', 'Vindere'),
                          m('th', 'Points'),
                          m('th.not-small', 'Præmie'),
                        ]),

                        participants && participants.length > 0 ?

                          // we got participants
                          ctrl.paginatedParticipants()[ctrl.currentPage()].map(function (participant) {
                            return [
                              m('tr',
                                {
                                  class: participant.screenName === ctrl.userDetails().screenName ?
                                    'user-in-row' : ''
                                },
                                m('td', participant.rank),
                                m('td', participant.screenName),
                                m('td', Utils.numberFormat(participant.points)),
                                m('td.not-small',
                                  participant.prize && participant.prize[0] && participant.prize[0].prize ?
                                    participant.prize[0].prize : 'Ingen præmie'
                                )
                              )
                            ];
                          }) :

                          // we are loading or got no winners
                          m('tr', [
                            m('td.only-small', { colspan: 3 },
                              m('.empty-table',
                                ctrl.loadingFeed() ? m('.loading-spinner') : 'Der er ikke fundet noget vindere'
                              )
                            ),
                            m('td.not-small', { colspan: 4 },
                              m('.empty-table',
                                ctrl.loadingFeed() ? m('.loading-spinner') : 'Der er ikke fundet noget vindere'
                              )
                            )
                          ]),
                      ]),

                    ]),

                    // pagination
                    ctrl.paginatedParticipants().length > 1 ?
                      m('.leaderboard__pagination',
                        m.component(Pagination, { list: ctrl.paginatedParticipants, activePage: ctrl.currentPage })
                      ) : '',

                    // place information at bottom
                    infoBlock(),

                  ]
            ])
          ];
        }
      };

      // route
      route('/', root);

      Utils.logDeprecated('LeaderBoard.js - component loaded'); // Added by MIPE 2024-05-14
    });
  });
